import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { H1, H3, P } from '@/components/Typography';
import ButtonDefault from '@/components/ButtonDefault';
import viewport1Img from '../../assets/img/orderingTerminalAppsVp1.png';
import viewport5Img from '../../assets/img/orderingTerminalAppsVp5.jpeg';
import AppStoreIcon from '../../assets/icons/appStoreBg.svg';
import AndroidIcon from '../../assets/icons/googlePlayBg.svg';
import Section from '@/components/Section';
import FirstViewport from '@/components/FirstViewport';
import { OrderTerminalAppsPageContent } from '@/pages/ordering-and-terminal-apps';
import KeyFutureCard from '@/components/KeyFutureCard';
import ReversibleCardsComponent from '@/components/ReversibleCardsComponent';
import PhoneOpenBankingCard from '@/components/PhoneOpenBankingCard';
import StepBlueCheckCard from '@/components/StepBlueCheckCard';

const FirstVpWrapper = styled.div`
  background: linear-gradient(118.47deg, #f8f9fa 3.57%, #e4e6f0 100%);
`;

const GradientViewport = styled(Section)`
  background: linear-gradient(#ffffff, #e7edf3);
  padding-bottom: 80px;
  @media (max-width: 800px) {
    padding-bottom: 1rem;
  }
`;

const DefaultViewport = styled(GradientViewport)<{
  withoutPaddingTop?: boolean;
  withoutPaddingBottom?: boolean;
}>`
  background: transparent;
  ${({ withoutPaddingTop }) => withoutPaddingTop && `padding-top: 0;`}
  ${({ withoutPaddingBottom }) => withoutPaddingBottom && `padding-bottom: 0;`}
`;

const StyledDescription = styled(P)`
  color: #495b6c;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 1rem;
`;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px;
  margin-top: 40px;
`;

const StyledButtonDefault = styled(ButtonDefault)`
  margin-top: 1.5rem;
`;

const StepsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px;
  margin-top: 40px;
`;

const AdditionalWrapper = styled.div`
  border-radius: 20px;
  background: linear-gradient(
    to right,
    #13273f 59%,
    rgba(77, 33, 97, 0),
    transparent
  );
  padding: 89px 40% 107px 70px;
`;

const FifthViewPort = styled.div`
  background-image: ${`url(${viewport5Img})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
`;

const GreenText = styled(P)`
  color: #2cd19e;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 19px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Button = styled(Link)`
  border: none;
  background: none;
  width: 150px;
  height: 44px;
`;

interface ShippingManagementProps {
  content: OrderTerminalAppsPageContent;
}

const OrderAndTerminalAppsContainer: React.FunctionComponent<
  ShippingManagementProps
> = ({
  content: { viewport1, viewport2, viewport3, viewport4, viewport5 },
}) => (
  <>
    <FirstVpWrapper>
      <FirstViewport img={viewport1Img}>
        <H1 style={{ marginBottom: `1.5rem` }}>
          <span className="accent-text">{viewport1.title1}</span>
          {` `}
          {viewport1.title2}
        </H1>
        <StyledDescription>{viewport1.description}</StyledDescription>
        <StyledButtonDefault to={viewport1.button.url}>
          {viewport1.button.label}
        </StyledButtonDefault>
      </FirstViewport>
    </FirstVpWrapper>

    <GradientViewport>
      <H3
        style={{
          maxWidth: 768,
          margin: `0 auto`,
          textAlign: `center`,
          marginTop: -10,
        }}
      >
        {viewport2.title}
      </H3>
      <CardWrapper>
        {viewport2.cards.map((el) => (
          <KeyFutureCard label={el.label} />
        ))}
      </CardWrapper>
    </GradientViewport>

    <DefaultViewport>
      <ReversibleCardsComponent cards={viewport3.cards} />
    </DefaultViewport>

    <PhoneOpenBankingCard />

    <GradientViewport>
      <H3 style={{ margin: `0 auto 16px`, textAlign: `center` }}>
        {viewport4.title}
      </H3>
      <StepsWrapper>
        {viewport4.carouselItems.map((item) => (
          <StepBlueCheckCard
            title={item.title}
            description={item.description}
          />
        ))}
      </StepsWrapper>
    </GradientViewport>

    <DefaultViewport>
      <FifthViewPort>
        <AdditionalWrapper>
          <GreenText>{viewport5.greenText}</GreenText>
          <H3 style={{ marginBottom: `1.4rem`, maxWidth: 450, color: `#fff` }}>
            {viewport5.title}
          </H3>
          <Buttons>
            <Button to={viewport5.iosAppUrl} target="_blank">
              <AppStoreIcon />
            </Button>
            <Button to={viewport5.androidAppUrl} target="_blank">
              <AndroidIcon />
            </Button>
          </Buttons>
        </AdditionalWrapper>
      </FifthViewPort>
    </DefaultViewport>
  </>
);

export default OrderAndTerminalAppsContainer;
