import React from 'react';
import Main from '../containers/Layout';
import orderTerminalAppsContent from '../../content/pages/ordering-and-terminal-apps.yml';
import OrderAndTerminalAppsContainer from '@/containers/toolkit/OrderingAndTerminalAppsContainer';

export interface OrderTerminalAppsPageContent {
  viewport1: {
    title1: string;
    title2: string;
    description: string;
    button: { label: string; url: string };
  };
  viewport2: {
    title: string;
    cards: { label: string }[];
  };
  viewport3: {
    cards: {
      title: string;
      subtitle: string;
      tickedDescription: { item: string }[];
      image: string;
    }[];
  };
  viewport4: {
    title: string;
    carouselItems: {
      title: string;
      description: string;
    }[];
  };
  viewport5: {
    greenText: string;
    title: string;
    androidAppUrl: string;
    iosAppUrl: string;
  };
}

const OrderAndTerminalApps: React.FunctionComponent = () => {
  const { viewport1, viewport2, viewport3, viewport4, viewport5 } =
    orderTerminalAppsContent as unknown as OrderTerminalAppsPageContent;
  return (
    <Main>
      <OrderAndTerminalAppsContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
          viewport5,
        }}
      />
    </Main>
  );
};

export default OrderAndTerminalApps;
