import React from 'react';
import styled from 'styled-components';
import { Body1, H3, Subtitle5 } from './Typography';
import phoneOBImage from '../assets/img/phoneOpenBanking.png';
import Tick from '../assets/icons/tick.svg';

const Wrapper = styled.div`
  background: linear-gradient(
    359deg,
    #f4f5f9 1.49%,
    rgba(255, 255, 255, 0) 97.48%
  );
`;

const AdditionalWrapper = styled.div`
  padding: 0 135px 97px;
  background-image: url(${phoneOBImage});
  background-size: 347px auto;
  background-repeat: no-repeat;
  background-position: bottom center;
`;

const Title = styled(H3)`
  margin-bottom: 80px;
  text-align: center;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 427px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

const ItemWrapper = styled.div`
  display: flex;
  gap: 13px;
`;

const TickWrapper = styled.div`
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  background-color: #2cd19e;
`;

const TickIcon = styled(Tick)`
  color: #fff;
  transform: scale(0.7);
`;

const ItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ItemDescription = styled(Body1)`
  color: #495b6c;
  font-weight: 500;
`;

const PhoneOpenBankingCard: React.FunctionComponent = () => (
  <Wrapper>
    <AdditionalWrapper>
      <Title>All orders come with free Open Banking</Title>
      <GridWrapper>
        <Column>
          <ItemWrapper>
            <div>
              <TickWrapper>
                <TickIcon />
              </TickWrapper>
            </div>
            <ItemColumn>
              <Subtitle5>Low fees</Subtitle5>
              <ItemDescription>
                Subscribe from{` `}
                <span style={{ fontWeight: 600 }}>£4.99 + VAT</span>. No setup
                costs and no additional terminal costs.
              </ItemDescription>
            </ItemColumn>
          </ItemWrapper>
          <ItemWrapper>
            <div>
              <TickWrapper>
                <TickIcon />
              </TickWrapper>
            </div>
            <ItemColumn>
              <Subtitle5>Instant settlement</Subtitle5>
              <ItemDescription>
                Payments are instantaneous. Your funds are deposited into your
                account the moment payments are made.
              </ItemDescription>
            </ItemColumn>
          </ItemWrapper>
        </Column>
        <Column>
          <ItemWrapper>
            <div>
              <TickWrapper>
                <TickIcon />
              </TickWrapper>
            </div>
            <ItemColumn>
              <Subtitle5>Easy setup</Subtitle5>
              <ItemDescription>
                Set up your account in a few minutes with ecommerce plug-ins and
                SDKs.
              </ItemDescription>
            </ItemColumn>
          </ItemWrapper>
          <ItemWrapper>
            <div>
              <TickWrapper>
                <TickIcon />
              </TickWrapper>
            </div>
            <ItemColumn>
              <Subtitle5>Bank-level security</Subtitle5>
              <ItemDescription>
                Our APIs are Open Banking and PSD2 compliant, ensuring secure
                access to the financial system.
              </ItemDescription>
            </ItemColumn>
          </ItemWrapper>
        </Column>
      </GridWrapper>
    </AdditionalWrapper>
  </Wrapper>
);

export default PhoneOpenBankingCard;
