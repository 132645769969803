import React from 'react';
import styled from 'styled-components';
import { P } from './Typography';
import Tick from '../assets/icons/tick.svg';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 28px 37px 28px 29px;
  border-radius: 10px;
  border: 1px solid #c4dbf0;
  background: #fff;
  box-shadow: 0px 12px 30px 0px rgba(129, 129, 165, 0.2);
`;

const Label = styled(P)`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

const AbsoluteWrapper = styled.div`
  position: absolute;
  left: -15px;
`;

const TickWrapper = styled.div`
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #2cd19e;
`;

const TickIcon = styled(Tick)`
  color: #fff;
  transform: scale(0.8);
`;

interface ThisComponentProps {
  label: string;
}

const KeyFutureCard: React.FunctionComponent<ThisComponentProps> = ({
  label,
}) => (
  <Wrapper>
    <AbsoluteWrapper>
      <TickWrapper>
        <TickIcon />
      </TickWrapper>
    </AbsoluteWrapper>
    <Label>{label}</Label>
  </Wrapper>
);

export default KeyFutureCard;
